import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";

function checkToken(token) {
  if (!token) throw new Error("Se requiere token para esta solicitud");
}

// Funicon que permite obtenertodas las gestiones
export async function getGestionesApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/gestiones/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite obtener todas las areas
export async function getAreasApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/areas/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite obtener todas las tipologias
export async function getTipologiasApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/tipologias/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite obtener todas las categorias
export async function getCategoriasApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/categorias/`;
  return makeRequest(url, "GET", token);
}
