import { Button, Col, Form, Input, Row, message, DatePicker, Select, Upload, Spin, Card } from "antd";
import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { FormStyle, ButtonStyleSecond, SpinStyle } from "../comons/styles";
import { useAuth } from "../../hooks/useAuth";
import { ShowOkAlert } from "../comons/Alertas/ShowAlerts";
import { UseComplaints } from "../../hooks/complaints/UseComplaints";
import { Titulo, Declaracion } from "./Textos";

const { Option } = Select;

export function FormComplaints() {
  const [fileList, setFileList] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(null);
  const { auth } = useAuth();
  const { addDenuncia } = UseComplaints();
  const idUser = auth.me?.id_user;

  // Funciones de manejo de archivos
  const handleChange = (file) => {
    const isValidType = /\.(pdf)$/i.test(file.name);
    if (!isValidType) {
      message.error('Solo se permiten archivos .pdf');
      return Upload.LIST_IGNORE;
    }
    formik.setFieldValue('anexos', file.originFileObj || file);
    setFileList([file]);
    return false;
  };

  // Configuración de Formik para la gestión del formulario
  const formik = useFormik({
    initialValues: {
      ...initialValues(idUser),
      implicados: [{ nombres_apellidos: "" }],
      testigos: [{ nombres_apellidos: "" }],
    },
    validationSchema: newSchema(),
    onSubmit: async (formValues) => {
      setIsLoading(true);
      try {
        if (fileList.length === 0) {
          await ShowOkAlert('Error', 'Debe cargar un archivo para continuar.', 'error');
          setIsLoading(false);
          return;
        }

        // Convertir los arrays de implicados y testigos a cadenas JSON
        formValues.implicados = formValues.implicados.length > 0 ? JSON.stringify(formValues.implicados) : "[]";
        formValues.testigos = formValues.testigos.length > 0 ? JSON.stringify(formValues.testigos) : "[]";
        formValues.anexos = fileList[0].originFileObj || fileList[0];

        // Envía la denuncia
        await addDenuncia(formValues);

        ShowOkAlert('Éxito', 'Denuncia agregada exitosamente', 'success');

        // Resetear Formik y estado
        formik.resetForm();
        setFileList([]);
        setIsAnonymous(null);
        setSelectedArea("");
        setIsLoading(false);

      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const errorMessage = errorData.__all__?.join(', ') || 'Error inesperado en el servidor';
          await ShowOkAlert('Error', errorMessage, 'error');
        } else {
          await ShowOkAlert('Error', error?.message || 'Error al procesar la solicitud.', 'error');
        }
      } finally {
        setIsLoading(false);
      }
    },
    onReset: () => {
      setFileList([]);
    },
  });

  // Agregar fila de implicados
  const addRowImplicados = () => {
    formik.setValues({
      ...formik.values,
      implicados: [
        ...formik.values.implicados,
        { nombres_apellidos: "" },
      ]
    });
  };

  // Eliminar fila de implicados
  const removeRowImplicados = (index) => {
    const updatedRows = [...formik.values.implicados];
    updatedRows.splice(index, 1);
    formik.setValues({
      ...formik.values,
      implicados: updatedRows
    });
  };

  // Agregar fila de testigos
  const addRowTestigos = () => {
    formik.setValues({
      ...formik.values,
      testigos: [
        ...formik.values.testigos,
        { nombres_apellidos: "" },
      ]
    });
  };

  // Eliminar fila de testigos
  const removeRowTestigos = (index) => {
    const updatedRows = [...formik.values.testigos];
    updatedRows.splice(index, 1);
    formik.setValues({
      ...formik.values,
      testigos: updatedRows
    });
  };

  return (
    <Spin style={SpinStyle} spinning={isLoading} tip="Cargando...">
      <Form
        layout="vertical"
        onFinish={formik.handleSubmit}
        style={FormStyle}
        encType="multipart/form-data"
      >
        <Titulo />

        <Row gutter={16} style={{ marginTop: 40 }}>
          <Col span={12}>
            <Form.Item
              label="Área de integridad laboral"
              validateStatus={formik.errors.area_integridad && formik.touched.area_integridad ? "error" : ""}
              help={formik.touched.area_integridad && formik.errors.area_integridad ? formik.errors.area_integridad : ""}
            >
              <Select
                name="area_integridad"
                value={formik.values.area_integridad}
                onChange={(value) => {
                  setSelectedArea(value);
                  formik.setFieldValue('area_integridad', value);
                  formik.resetForm();
                  formik.setFieldValue('area_integridad', value);

                  if (value === "Comité de convivencia laboral") {
                    formik.setFieldValue("anonimato", false);
                    setIsAnonymous(false);
                  } else {
                    formik.setFieldValue("anonimato", null);
                    setIsAnonymous(true);
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una opción"
              >
                <Option value="Gestión Humana">Gestión Humana</Option>
                <Option value="Comité de convivencia laboral">Comité de convivencia laboral</Option>
              </Select>

            </Form.Item>
          </Col>
        </Row>

        {selectedArea === "Gestión Humana" && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Nombre de la denuncia"
                validateStatus={formik.errors.nombre_denuncia && formik.touched.nombre_denuncia ? "error" : ""}
                help={formik.touched.nombre_denuncia && formik.errors.nombre_denuncia ? formik.errors.nombre_denuncia : ""}
              >
                <Select
                  value={formik.values.nombre_denuncia}
                  onChange={(value) => {
                    formik.setFieldValue("nombre_denuncia", value);

                    if (value === "Acoso sexual") {
                      formik.setFieldValue("anonimato", false);
                      setIsAnonymous(false);
                    } else {
                      formik.setFieldValue("anonimato");
                      setIsAnonymous(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Seleccione una opción"
                >
                  <Option value="Acoso sexual">Quejas por acoso sexual (No Anónima)</Option>
                  <Option value="Incumplimiento desconexión laboral">Incumplimiento desconexión laboral</Option>
                  <Option value="Sugerencias y quejas">Sugerencias y quejas</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="¿Es Anónima?"
                validateStatus={formik.errors.anonimato && formik.touched.anonimato ? "error" : ""}
                help={formik.touched.anonimato && formik.errors.anonimato ? formik.errors.anonimato : ""}
              >
                <Select
                  value={formik.values.anonimato}
                  onChange={(value) => {
                    formik.setFieldValue("anonimato", value);
                    setIsAnonymous(value);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Seleccione una opción"
                  disabled={formik.values.nombre_denuncia === "Acoso sexual"}
                >
                  <Option value={true}>Sí</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedArea === "Comité de convivencia laboral" && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Nombre de la denuncia"
                validateStatus={formik.errors.nombre_denuncia && formik.touched.nombre_denuncia ? "error" : ""}
                help={formik.touched.nombre_denuncia && formik.errors.nombre_denuncia ? formik.errors.nombre_denuncia : ""}
              >
                <Input
                  name="nombre_denuncia"
                  value={formik.values.nombre_denuncia}
                  onChange={(e) => formik.setFieldValue("nombre_denuncia", e.target.value)}
                  onBlur={formik.handleBlur}
                  placeholder="Digite el nombre de la denuncia"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {isAnonymous === false && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Nombre Completo"
                validateStatus={
                  formik.errors.nombre_completo && formik.touched.nombre_completo ? "error" : ""}
                help={formik.touched.nombre_completo && formik.errors.nombre_completo ? formik.errors.nombre_completo : ""}
              >
                <Input
                  name="nombre_completo"
                  value={formik.values.nombre_completo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Carlos Andres Perez Muñoz"
                  maxLength={15}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Cargo"
                validateStatus={formik.errors.cargo && formik.touched.cargo ? "error" : ""}
                help={formik.touched.cargo && formik.errors.cargo ? formik.errors.cargo : ""}
              >
                <Input
                  name="cargo"
                  value={formik.values.cargo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Coordinador"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {isAnonymous === false && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Celular"
                validateStatus={
                  formik.errors.telefono_denuncia && formik.touched.telefono_denuncia ? "error" : ""}
                help={formik.touched.telefono_denuncia && formik.errors.telefono_denuncia ? formik.errors.telefono_denuncia : ""}
              >
                <Input
                  type="tel"
                  name="telefono_denuncia"
                  value={formik.values.telefono_denuncia}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="3109876465"
                  maxLength={15}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Correo Electrónico"
                validateStatus={
                  formik.errors.correo_denuncia && formik.touched.correo_denuncia ? "error" : ""}
                help={formik.touched.correo_denuncia && formik.errors.correo_denuncia ? formik.errors.correo_denuncia : ""}
              >
                <Input
                  type="email"
                  name="correo_denuncia"
                  value={formik.values.correo_denuncia}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="usuario@ejemplo.com"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha de los hechos"
              validateStatus={formik.errors.fecha_hechos && formik.touched.fecha_hechos ? "error" : ""}
              help={formik.touched.fecha_hechos && formik.errors.fecha_hechos ? formik.errors.fecha_hechos : ""}
            >
              <DatePicker
                style={{ width: '100%' }}
                name="fecha_hechos"
                value={formik.values.fecha_hechos ? dayjs(formik.values.fecha_hechos, "YYYY-MM-DD") : null}
                onChange={(date) => {
                  formik.setFieldValue('fecha_hechos', date ? date.format("YYYY-MM-DD") : null);
                }}
                onBlur={() => formik.setFieldTouched('fecha_hechos')}
                onFocus={() => formik.setFieldValue('fecha_hechos', null)}
                placeholder="Seleccione una fecha..."
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  return current && current > dayjs().endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Lugar de los hechos"
              validateStatus={formik.errors.lugar_hechos && formik.touched.lugar_hechos ? "error" : ""}
              help={formik.touched.lugar_hechos && formik.errors.lugar_hechos ? formik.errors.lugar_hechos : ""}
            >
              <Input
                name="lugar_hechos"
                value={formik.values.lugar_hechos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Oficina de trabajo"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Descripción de los hechos"
              validateStatus={formik.errors.descripcion_hechos && formik.touched.descripcion_hechos ? "error" : ""}
              help={formik.touched.descripcion_hechos && formik.errors.descripcion_hechos ? formik.errors.descripcion_hechos : ""}
            >
              <Input.TextArea
                name="descripcion_hechos"
                value={formik.values.descripcion_hechos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Describa los hechos ocurridos..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Card title="Personas implicadas" bordered style={{ marginTop: 24 }}>
          <Row justify="start" style={{ marginBottom: 16 }}>
            <Button type="dashed" onClick={addRowImplicados} icon={<PlusOutlined />}>
              Añadir implicado
            </Button>
          </Row>

          {Array.isArray(formik.values.implicados) && formik.values.implicados.map((Rowimplicado, index) => (
            <Row gutter={16} key={index} align="middle">
              <Col span={20}>
                <Form.Item
                  label="Nombre y Apellidos"
                  validateStatus={formik.errors.implicados?.[index]?.nombres_apellidos && formik.touched.implicados?.[index]?.nombres_apellidos ? "error" : ""}
                  help={formik.touched.implicados?.[index]?.nombres_apellidos && formik.errors.implicados?.[index]?.nombres_apellidos ? formik.errors.implicados?.[index]?.nombres_apellidos : ""}
                >
                  <Input
                    name={`implicados[${index}].nombres_apellidos`}
                    value={Rowimplicado.nombres_apellidos}
                    onChange={(e) => formik.setFieldValue(`implicados[${index}].nombres_apellidos`, e.target.value)}
                    onBlur={formik.handleBlur}
                    placeholder="Nombre completo del implicado"
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  type="primary"
                  danger
                  onClick={() => removeRowImplicados(index)}
                  icon={<MinusCircleOutlined />}
                  style={{ backgroundColor: '#CC302B', color: '#fff' }}
                >
                  Quitar
                </Button>
              </Col>
            </Row>
          ))}
        </Card>

        <Card title="Personas testigos" bordered style={{ marginTop: 24 }}>
          <Row justify="start" style={{ marginBottom: 16 }}>
            <Button
              type="dashed"
              onClick={addRowTestigos} icon={<PlusOutlined />} >
              Añadir Testigo
            </Button>
          </Row>

          {Array.isArray(formik.values.implicados) && formik.values.testigos.map((Rowtestigos, index) => (
            <Row gutter={16} key={index} align="middle">
              <Col span={20}>
                <Form.Item
                  label="Nombre y Apellidos"
                  validateStatus={formik.errors.testigos?.[index]?.nombres_apellidos && formik.touched.testigos?.[index]?.nombres_apellidos ? "error" : ""}
                  help={formik.touched.testigos?.[index]?.nombres_apellidos && formik.errors.testigos?.[index]?.nombres_apellidos ? formik.errors.testigos?.[index]?.nombres_apellidos : ""}
                >
                  <Input
                    name={`testigos[${index}].nombres_apellidos`}
                    value={Rowtestigos.nombres_apellidos}
                    onChange={(e) => formik.setFieldValue(`testigos[${index}].nombres_apellidos`, e.target.value)}
                    onBlur={formik.handleBlur}
                    placeholder="Nombre completo del testigo"
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  type="primary"
                  danger
                  onClick={() => removeRowTestigos(index)}
                  icon={<MinusCircleOutlined />}
                  style={{ backgroundColor: '#CC302B', color: '#fff' }}
                >
                  Quitar
                </Button>
              </Col>
            </Row>
          ))}
        </Card>

        <Row gutter={16} style={{ marginTop: 24 }}>
          <Col span={24}>
            <Form.Item
              label="anexos de la denuncia"
              validateStatus={formik.errors.anexos && formik.touched.anexos ? "error" : ""}
              help={formik.touched.anexos && formik.errors.anexos ? formik.errors.anexos : ""}
            >
              <Upload.Dragger
                name="anexos"
                accept=".pdf"
                fileList={fileList}
                beforeUpload={handleChange}
                maxCount={1}
                onRemove={() => {
                  formik.setFieldValue("anexos", null);
                  setFileList([]);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ color: '#CC302B' }} />
                </p>
                <p className="ant-upload-text">Haz clic o arrastra el archivo aquí</p>
                <p className="ant-upload-hint">Solo se permiten archivos con extensión .pdf</p>
              </Upload.Dragger>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={ButtonStyleSecond}>
                Enviar
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Declaracion />
      </Form>
    </Spin>
  );
}

function initialValues(idUser) {
  return {
    id_user: idUser,
    area_integridad: undefined,
    nombre_denuncia: undefined,
    anonimato: null,
    nombre_completo: "",
    cargo: "",
    telefono_denuncia: "",
    correo_denuncia: "",
    fecha_hechos: null,
    lugar_hechos: "",
    descripcion_hechos: "",
    anexos: null,
    implicados: [],
    testigos: [],
  };
}

function newSchema() {
  return Yup.object({
    area_integridad: Yup.string().required("Requerido"),
    nombre_denuncia: Yup.string().required("Requerido"),
    anonimato: Yup.boolean().required("Requerido"),
    nombre_completo: Yup.string().when('anonimato', { is: false, then: (schema) => schema.required("Requerido"), otherwise: (schema) => schema.notRequired(), }),
    cargo: Yup.string().when('anonimato', { is: false, then: (schema) => schema.required("Requerido"), otherwise: (schema) => schema.notRequired(), }),
    telefono_denuncia: Yup.string().when('anonimato', { is: false, then: (schema) => schema.matches(/^[0-9]+$/, "El teléfono debe ser numérico").required("Requerido"), otherwise: (schema) => schema.notRequired(), }),
    correo_denuncia: Yup.string().when('anonimato', { is: false, then: (schema) => schema.email("El correo no es válido").required("Requerido"), otherwise: (schema) => schema.notRequired(), }), fecha_hechos: Yup.date().required("Requerido"),
    lugar_hechos: Yup.string().required("Requerido"),
    descripcion_hechos: Yup.string().required("Requerido"),
    anexos: Yup.mixed().required("Requerido"),
    implicados: Yup.array().of(Yup.object().shape({ nombres_apellidos: Yup.string().required("Requerido"), })).min(1, "Debe haber al menos un implicado"),
    testigos: Yup.array().of(Yup.object().shape({ nombres_apellidos: Yup.string().required("Requerido"), })).min(1, "Debe haber al menos un testigo"),
  });
}