import { BASE_API } from "../../utils/constants";
import { makeRequest, makeRequestFileData } from "../Request";

function checkToken(token) {
  if (!token) throw new Error("Se requiere token para esta solicitud");
}

// Funicon que permite obtener un documento
export async function getDocumentApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/documentos/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite agregar un documento
export async function addDocumentApi(data, token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/documentos/save_archivo/`;
  return makeRequestFileData(url, "POST", token, data);
}

// Funicon que permite actualizar un documento
export async function updateDocumentApi(id, data, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un ID para actualizar el documento");
  const url = `${BASE_API}/GestionDocumental/api/documentos/${id}/`;
  return makeRequestFileData(url, "PUT", token, data);
}

// Funicon que permite eliminar un documento
export async function deleteDocumentApi(id, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un ID para eliminar el documento");
  const url = `${BASE_API}/GestionDocumental/api/documentos/${id}/`;
  return makeRequest(url, "DELETE", token);
}

// Funicon que permite actualizar  un archivo del Documento
export async function updateDocumentFileApi(id, token, data) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/documentos/${id}/archivo/`;
  return makeRequestFileData(url, "PUT", token, data);
}

// Funicon que permite eliminar un archivo del documento
export async function deleteDocumentFileApi(id, token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/documentos/${id}/archivo/`;
  return makeRequest(url, "DELETE", token);
}

export const getDocumentUrl = (id) => id ? `${BASE_API}/GestionDocumental/api/documentos/${id}/` : `${BASE_API}/GestionDocumental/api/documentos/`;