// Función para realizar solicitudes genéricas con JSON
export async function makeRequest(url, method, token, data) {
  try {
    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, params);

    let result;
    if (response.status !== 204) {
      result = await response.json();
    }

    if (!response.ok) {
      throw new Error(
        `Error: ${response.status} - ${result?.message || response.statusText}`
      );
    }

    return [result, response];
  } catch (error) {
    throw error;
  }
}

// Función para realizar solicitudes con FormData
export async function makeRequestFormData(url, method, token, data) {
  try {
    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(
        `Error: ${response.status} - ${result.message || response.statusText}`
      );
    }

    return [result, response];
  } catch (error) {
    throw error;
  }
}

// Función para realizar solicitudes con archivos y otros datos
export async function makeRequestFileData(url, method, token, data) {
  try {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(
        `Error: ${response.status} - ${result.message || response.statusText}`
      );
    }

    return [result, response];
  } catch (error) {
    throw error;
  }
}

// Función para realizar solicitudes HTTP que incluyen archivos y datos adicionales en formato FormData
export async function makeRequestFileDataJson(url, method, token, data) {
  try {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'peligros_schema') {
          // Si el campo es un objeto JSON, lo convertimos en cadena
          if (typeof data[key] === 'object') {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return [result, response];
  } catch (error) {
    throw error;
  }
}

// Función para realizar solicitudes HTTP que incluyen archivos y datos adicionales en formato FormData
export async function makeRequestFileDataJsonGD(url, method, token, data) {
  try {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === "archivo" && data[key].uid) {
          formData.append("archivo", data[key]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    if (!response.ok) {
      const result = await response.json();
      throw new Error(
        `Error: ${response.status} - ${result.message || response.statusText}`
      );
    }

    const result = await response.json();
    return [result, response];
  } catch (error) {
    throw error;
  }
}

// Función para enviar la solicitud basada en el tipo de datos
export async function sendRequest(url, method, token, data) {
  const isFormData =
    data instanceof FormData ||
    Object.values(data).some((value) => value instanceof File);

  try {
    if (isFormData) {
      return await makeRequestFileData(url, method, token, data);
    } else {
      return await makeRequest(url, method, token, data);
    }
  } catch (error) {
    throw error;
  }
}