// Componente FormDocument: Formulario dinámico para gestionar documentos
import { Button, Col, Form, Input, Row, message, DatePicker, Select, Upload, Spin } from "antd";
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { FormStyle, ButtonStyle, ButtonStyleSecond } from "../comons/styles";
import { UseDocument } from "../../hooks/managementDocument/UseDocuments";
import { useAuth } from "../../hooks/useAuth";
import { ShowOkAlert } from "../comons/Alertas/ShowAlerts";

const { Option } = Select;

export function FormDocument(props) {
  // Props para manejar el cierre del formulario, refrescar datos y opciones iniciales
  const { onClose, onRefetch, documents, trds } = props;
  const [fileList, setFileList] = useState([]);
  const { addDocument, updateDocument } = UseDocument();
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useAuth();
  const idUser = auth.me?.id_user;

  // Efecto para establecer valores iniciales cuando hay datos existentes
  useEffect(() => {
    if (documents) {
      formik.setValues(initialValues(documents, idUser));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  // Configuración de Formik para la gestión del formulario
  const formik = useFormik({
    initialValues: initialValues(documents, idUser),
    validationSchema: documents ? updateSchema() : newSchema(),
    onSubmit: async (formValues) => {
      setIsLoading(true);
      try {
        // Validación adicional para asegurar que se adjunte un archivo
        if (fileList.length === 0) {
          await ShowOkAlert('Error', 'Debe cargar un archivo para continuar.', 'error');
          setIsLoading(false);
          return;
        }
        formValues.archivo = fileList[0].originFileObj || fileList[0];
        if (documents) {
          await updateDocument(documents.id_documento, formValues);
          ShowOkAlert('Éxito', 'Documento actualizado exitosamente', 'success');
        } else {
          await addDocument(formValues);
          await ShowOkAlert('Éxito', 'Documento agregado exitosamente', 'success');
        }
        onRefetch();
        onClose();
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          const errorMessage = errorData.__all__?.join(', ') || 'Error inesperado en el servidor';
          await ShowOkAlert('Error', errorMessage, 'error');
        } else {
          await ShowOkAlert('Error', error?.message || 'Error al procesar la solicitud.', 'error');
        }
      } finally {
        setIsLoading(false);
      }
    },
    onReset: () => {
      setFileList([]);
    },
  });

  // Función para manejar el cambio de archivo
  const handleChange = (file) => {
    const isValidType = /\.(pdf)$/i.test(file.name);
    if (!isValidType) {
      message.error('Solo se permiten archivos .pdf');
      return Upload.LIST_IGNORE;
    }

    // Actualización de valores de Formik y lista de archivos
    formik.setFieldValue('archivo', file.originFileObj || file);
    setFileList([file]);
    return false;
  };

  const formatNumber = (value) => {
    if (!value) return "";
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const cleanNumber = (value) => {
    if (!value) return "";
    return value.replace(/,/g, "");
  };

  return (
    <Spin spinning={isLoading} tip="Procesando...">
      <Form
        layout="vertical"
        onFinish={formik.handleSubmit}
        style={FormStyle}
        encType="multipart/form-data"
      >

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Retención Documental *:"
              validateStatus={formik.errors.id_trd && formik.touched.id_trd ? "error" : ""}
              help={formik.touched.id_trd && formik.errors.id_trd ? formik.errors.id_trd : ""}
            >
              <Select
                name="trd"
                value={formik.values.id_trd}
                onChange={(value) => formik.setFieldValue('id_trd', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una retención documental"
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }

              >
                {(trds || []).map((trd) => (
                  <Option key={trd.id_trd} value={trd.id_trd}>
                    {trd.nombre_trd || 'Sin categoría'}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Nombre del Documento *:"
              validateStatus={formik.errors.nom_documento && formik.touched.nom_documento ? "error" : ""}
              help={formik.touched.nom_documento && formik.errors.nom_documento ? formik.errors.nom_documento : ""}
            >
              <Input
                name="nom_documento"
                value={formik.values.nom_documento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Acta del Comité de Gestión de TIC..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>

          <Col span={12}>
            <Form.Item
              label="Número del Documento"
              validateStatus={formik.errors.num_documento && formik.touched.num_documento ? "error" : ""}
              help={formik.touched.num_documento && formik.errors.num_documento ? formik.errors.num_documento : ""}
            >
              <Input
                type="text"
                name="num_documento"
                value={formik.values.num_documento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="12345..."
              />

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Emisor *:"
              validateStatus={formik.errors.emisor && formik.touched.emisor ? "error" : ""}
              help={formik.touched.emisor && formik.errors.emisor ? formik.errors.emisor : ""}
            >
              <Input
                name="emisor"
                value={formik.values.emisor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Juan Lopez..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha del Documento *:"
              validateStatus={formik.errors.fecha_documento && formik.touched.fecha_documento ? "error" : ""}
              help={formik.touched.fecha_documento && formik.errors.fecha_documento ? formik.errors.fecha_documento : ""}
            >
              <DatePicker
                style={{ width: '100%' }}
                name="fecha_documento"
                value={formik.values.fecha_documento ? dayjs(formik.values.fecha_documento, "YYYY-MM-DD") : null}
                onChange={(date) => {
                  formik.setFieldValue('fecha_documento', date ? date.format("YYYY-MM-DD") : null);
                }}
                onBlur={() => formik.setFieldTouched('fecha_documento')}
                onFocus={() => formik.setFieldValue('fecha_documento', null)}
                placeholder="Seleccione una fecha..."
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  return current && current > dayjs().endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Valor del documento"
              validateStatus={formik.errors.valor_poliza && formik.touched.valor_poliza ? "error" : ""}
              help={formik.touched.valor_poliza && formik.errors.valor_poliza ? formik.errors.valor_poliza : ""}
            >
              <Input
                name="valor_poliza"
                value={formatNumber(formik.values.valor_poliza)}
                onChange={(e) => {
                  const rawValue = cleanNumber(e.target.value);
                  formik.setFieldValue("valor_poliza", rawValue);
                }}
                onBlur={(e) => {
                  const rawValue = cleanNumber(e.target.value);
                  const formattedValue = rawValue === "" ? "0.00" : parseFloat(rawValue).toFixed(2);
                  formik.setFieldValue("valor_poliza", formattedValue);
                }}
                placeholder="1,000,000,000.00"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Fecha final"
              validateStatus={formik.errors.fecha_poliza && formik.touched.fecha_poliza ? "error" : ""}
              help={formik.touched.fecha_poliza && formik.errors.fecha_poliza ? formik.errors.fecha_poliza : ""}
            >
              <DatePicker
                style={{ width: '100%' }}
                name="fecha_poliza"
                value={formik.values.fecha_poliza ? dayjs(formik.values.fecha_poliza, "YYYY-MM-DD") : null}
                onChange={(date) => {
                  formik.setFieldValue('fecha_poliza', date ? date.format("YYYY-MM-DD") : null);
                }}
                onBlur={() => formik.setFieldTouched('fecha_poliza')}
                placeholder="Seleccione una fecha..."
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Estado del documento"
              validateStatus={formik.errors.estado_poliza && formik.touched.estado_poliza ? "error" : ""}
              help={formik.touched.estado_poliza && formik.errors.estado_poliza ? formik.errors.estado_poliza : ""}
            >
              <Select
                value={formik.values.estado_poliza || ""}
                onChange={(value) => formik.setFieldValue('estado_poliza', value || null)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una Opción"
              >
                <Option value="">Ninguno</Option>
                <Option value="activa">Activa</Option>
                <Option value="inactiva">Inactiva</Option>
                <Option value="cancelada">Cancelada</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Descripción *:"
              validateStatus={formik.errors.descripcion && formik.touched.descripcion ? "error" : ""}
              help={formik.touched.descripcion && formik.errors.descripcion ? formik.errors.descripcion : ""}
            >
              <Input.TextArea
                name="descripcion"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Digite una descripción..."
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Observación"
              validateStatus={formik.errors.observacion && formik.touched.observacion ? "error" : ""}
              help={formik.touched.observacion && formik.errors.observacion ? formik.errors.observacion : ""}
            >
              <Input.TextArea
                name="observacion"
                value={formik.values.observacion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Digite una observación..."
              />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={16} >
          <Col span={12}>
            <Form.Item
              validateStatus={formik.errors.archivo && formik.touched.archivo ? "error" : ""}
              help={formik.touched.archivo && formik.errors.archivo ? formik.errors.archivo : ""}
            >
              <Upload
                name="archivo"
                accept=".pdf"
                fileList={fileList}
                beforeUpload={handleChange}
                maxCount={1}
                onRemove={() => {
                  formik.setFieldValue('archivo', null);
                  setFileList([]);
                }}
              >
                <Button icon={<UploadOutlined />} style={ButtonStyle}>
                  {documents ? "Nuevo Archivo" : "Adjuntar Archivo"}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={ButtonStyleSecond}>
                {documents ? "Actualizar" : "Crear"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

// Función para definir valores iniciales del formulario
function initialValues(data, idUser) {
  return {
    id_user: idUser,
    id_trd: data?.id_trd || null,
    nom_documento: data?.nom_documento || "",
    num_documento: data?.num_documento || "",
    emisor: data?.emisor || "",
    fecha_documento: data?.fecha_documento || null,
    valor_poliza: data?.valor_poliza || "0.00",
    fecha_poliza: data?.fecha_poliza || dayjs().format("YYYY-MM-DD"),
    estado_poliza: data?.estado_poliza || "",
    descripcion: data?.descripcion || "",
    observacion: data?.observacion || "",
    archivo: data?.archivo || null,
    vigente: data?.vigente || false,
  };
}

// Esquema de validación para nuevo documento
function newSchema() {
  return Yup.object({
    id_trd: Yup.string().required("Requerido"),
    nom_documento: Yup.string().required(" Requerido"),
    num_documento: Yup.string(),
    emisor: Yup.string().required("Requerido"),
    fecha_documento: Yup.date().required("Requerido"),
    valor_poliza: Yup.number().nullable().optional(),
    fecha_poliza: Yup.date().nullable().optional(),
    estado_poliza: Yup.string().nullable().optional(),
    descripcion: Yup.string().required("Requerido"),
    observacion: Yup.string().required("Requerido"),
    archivo: Yup.mixed().required("Requerido"),
  });
}

// Esquema de validación para actualización de documento
function updateSchema() {
  return Yup.object({
    id_trd: Yup.string().required("Requerido"),
    nom_documento: Yup.string().required("Requerido"),
    num_documento: Yup.string(),
    emisor: Yup.string().required("Requerido"),
    fecha_documento: Yup.date().required(" Requerido"),
    valor_poliza: Yup.number().nullable().optional(),
    fecha_poliza: Yup.date().nullable().optional(),
    estado_poliza: Yup.string().nullable().optional(),
    descripcion: Yup.string().required("Requerido"),
    observacion: Yup.string().required("Requerido"),
    archivo: Yup.mixed().required("Requerido"),
  });
}