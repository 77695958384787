import React, { useCallback, useEffect, useState } from 'react';
import { UseTrd } from '../../hooks/managementDocument/UseTrds';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from 'antd';
import { FormTrd } from '../../components/documentManagement/FormTrds';
import { TableDocument } from '../../components/comons/Tables/TableDocument';
import { UseOther } from '../../hooks/managementDocument/UseOther';
import { ShowAlert, ShowOkAlert, messagesDeleteTrd } from "../../components/comons/Alertas/ShowAlerts";

// Definición de los encabezados de la tabla para mostrar las TRD
const TRDHeaders = [
  { label: 'Id_Retención', field: 'id_trd' },
  { label: 'Gestión', field: 'gestion' },
  { label: 'Área', field: 'area' },
  { label: 'Tipología', field: 'tipologia' },
  { label: 'Categoria', field: 'categoria' },
  { label: 'Años de retención', field: 'anios_retencion' },
  { label: 'Soporte', field: 'soporte' },
  { label: 'Seguridad', field: 'seguridad' },
  { label: 'Disposición Final', field: 'disposicion_final' },
];

// Columnas adicionales para el popover
const popoverColumns = [
  { label: 'Id_Retención', field: 'id_trd' },
]

export function PageTrd() {
  // Estados locales para controlar el re-fetch, modal y su contenido
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  // Hooks personalizados para manejar TRDs y otros datos relacionados
  const { loading: trdsLoading, trds, getTRD, deleteTRD } = UseTrd();
  const { getGestiones, getAreas, getTipologias, getCategorias, gestiones, areas, tipologias, categorias } = UseOther();

  // Función para alternar el estado de re-fetch
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  // Función para abrir/cerrar el modal
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);

  // useEffect para cargar los datos iniciales al montar el componente o cambiar `refetch`
  useEffect(() => {
    getTRD();
    getGestiones();
    getAreas();
    getTipologias();
    getCategorias();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch]);

  // Función para manejar la adición de una nueva TRD
  const handleAdd = useCallback(() => {
    setContentModal(<FormTrd
      onClose={openCloseModal}
      onRefetch={onRefetch}
      gestiones={gestiones}
      areas={areas}
      tipologias={tipologias}
      categorias={categorias}
    />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch, gestiones, areas, tipologias, categorias]);

  // Función para manejar la actualización de una TRD existente
  const handleUpdate = useCallback(
    (trds) => {
      setContentModal(
        <FormTrd
          onClose={openCloseModal}
          onRefetch={onRefetch}
          trds={trds}
          gestiones={gestiones}
          areas={areas}
          tipologias={tipologias}
          categorias={categorias}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch, gestiones, areas, tipologias, categorias]
  );

  // Función para manejar la eliminación de una TRD
  const handleDeleteTrd = useCallback(
    async (trd) => {
      const messages = messagesDeleteTrd();

      try {
        const result = await ShowAlert(
          messages.confirmTitle,
          messages.confirmText,
          'warning',
          messages.confirmButtonText,
          'Cancelar'
        );

        if (result === null) {
          return;
        }

        if (result.isConfirmed) {
          await deleteTRD(trd.id_trd);
          await ShowOkAlert(messages.successMessage, messages.successDescription, 'success');
          onRefetch();
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          ShowOkAlert("Error al eliminar TRD", error.response.data.detail, 'error');
        } else {
          ShowOkAlert(messages.errorMessage, messages.errorDescription, 'error');
        }
        console.error('Error al eliminar TRD:', error);
      }
    },
    [deleteTRD, onRefetch]
  )

  // Mapeos para enlazar IDs con nombres legibles
  const gestionMap = new Map((gestiones || []).map(g => [g.id_gestion, g]));
  const areaMap = new Map((areas || []).map(a => [a.id_area, a]));
  const tipologiaMap = new Map((tipologias || []).map(t => [t.id_tipologia, t]));
  const categoriaMap = new Map((categorias || []).map(c => [c.id_categoria, c]));

  // Combinación de datos de TRD con nombres legibles en lugar de IDs
  const combinedData = (trds || []).map((trd) => {
    const gestion = gestionMap.get(trd.gestion || trd.id_gestion) || {};
    const area = areaMap.get(trd.area || trd.id_area) || {};
    const tipologia = tipologiaMap.get(trd.tipologia || trd.id_tipologia) || {};
    const categoria = categoriaMap.get(trd.categoria || trd.id_categoria) || {};

    return {
      ...trd,
      gestion: gestion.nombre || 'No encontrada',
      area: area.nombre || 'No encontrada',
      tipologia: tipologia.nombre || 'No encontrada',
      categoria: categoria.nombre || 'No encontrada',
    };
  });

  return (
    <>
      {(trdsLoading || !gestiones || !areas || !tipologias || !categorias) ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableDocument
          Data={combinedData}
          columns={TRDHeaders}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleEliminarTrd={handleDeleteTrd}
        />
      )}
      <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Retención Documental"
        destroyOnClose={true}
      >
        {contentModal}
      </Modal>
    </>
  );
}