import { useState } from "react";
import { getDenunciaApi, addDenunciaApi, updateDenunciaApi, deleteDenunciaApi, updateRespuestaDenunciaApi } from "../../Api/complaints/RequestsComplaints";
import { useAuth } from "../useAuth";

// Custom hook para manejar la gestión de denuncias
export function UseComplaints() {
  const [state, setState] = useState({ loading: true, error: null, denuncias: null, });
  const { loading, error, denuncias } = state;
  const { auth } = useAuth();

  // Función para obtener las denuncias desde la API
  const getDenuncia = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result] = await getDenunciaApi(auth.token);
      setState((prevState) => ({ ...prevState, loading: false, denuncias: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  // Función para agregar una nueva denuncia a través de la API
  const addDenuncia = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await addDenunciaApi(data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  // Función para actualizar un documento existente a través de la API
  const updateDenuncia = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateDenunciaApi(id, data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  // Función para eliminar un documento específico a través de la API
  const deleteDenuncia = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await deleteDenunciaApi(id, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

    // Función para actualizar la respuesta de una denuncia
    const updateRespuestaDenuncia = async (id, respuesta) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const response = await updateRespuestaDenunciaApi(id, { respuesta }, auth.token);
        setState((prevState) => ({ ...prevState, loading: false }));
        return response; // Devuelves la respuesta si es necesario
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };

  return {
    loading,
    error,
    denuncias,
    getDenuncia,
    addDenuncia,
    updateDenuncia,
    deleteDenuncia,
    updateRespuestaDenuncia,
  };
}