import { useState } from "react";
import { getDocumentApi, addDocumentApi, updateDocumentApi, deleteDocumentApi, updateDocumentFileApi, deleteDocumentFileApi } from "../../Api/documentManagement/RequestsDocument";
import { useAuth } from "../useAuth";
import { message } from "antd";

// Custom hook para manejar la gestión de documentos
export function UseDocument() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    documents: null,
  });

  const { loading, error, documents } = state;
  const { auth } = useAuth();

  // Función para obtener las documentos desde la API
  const getDocuments = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result] = await getDocumentApi(auth.token);
      setState((prevState) => ({ ...prevState, loading: false, documents: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  // Función para agregar un nuevo documento a través de la API
  const addDocument = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await addDocumentApi(data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  // Función para actualizar un documento existente a través de la API
  const updateDocument = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateDocumentApi(id, data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  // Función para eliminar un documento específico a través de la API
  const deleteDocument = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await deleteDocumentApi(id, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  // Función para actualizar un archivo existente a través de la API
  const updateDocumentFile = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateDocumentFileApi(id, data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  // Función para eliminar una archivo específica a través de la API
  const deleteDocumentFile = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await deleteDocumentFileApi(id, auth.token);
      message.success('Archivo eliminado exitosamente');
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      message.error(`Error al eliminar el archivo: ${error.message || 'No se pudo eliminar el archivo'}`);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  // Devuelve los datos y las funciones que pueden ser utilizadas por otros componentes
  return {
    loading,
    error,
    documents,
    getDocuments,
    addDocument,
    updateDocument,
    deleteDocument,
    deleteDocumentFile,
    updateDocumentFile,
  };
}