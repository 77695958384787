import Swal from 'sweetalert2';

// Función ShowAlert para mostrar cualquier alerta
export function ShowAlert(
  title,
  text,
  icon,
  confirmButtonText,
  cancelButtonText,
  showCancelButton = true,
  confirmButtonColor = '#CC302B',
  cancelButtonColor = '#444444'
) {
  return new Promise((resolve) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: showCancelButton,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(result);
      } else {
        resolve(null);
      }
    }).catch(() => {
      resolve(null);
    });
  });
}

// Función ShowAlert para mostrar solo un botón "OK"
export function ShowOkAlert(
  title,
  text,
  icon,
  confirmButtonText = "OK",
  confirmButtonColor = '#08131A'
) {
  return new Promise((resolve) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonColor: confirmButtonColor,
      confirmButtonText: confirmButtonText,
    }).then(() => {
      resolve();
    });
  });
}

// Función para obtener los mensajes para eliminar TRD
export function messagesDeleteTrd() {
  return {
    confirmTitle: "¿Deseas eliminar esta TRD?",
    confirmText: "Esta acción es irreversible.",
    confirmButtonText: "Eliminar TRD",
    successMessage: "¡TRD eliminada!",
    successDescription: "La TRD se eliminó exitosamente.",
    errorMessage: "Error al eliminar TRD",
    errorDescription: "No se puede eliminar este TRD porque está relacionado con documentos.",
  };
}

// Función para obtener los mensajes para eliminar documentos
export function messagesDeleteDocument() {
  return {
    confirmTitle: "¿Deseas eliminar este documento?",
    confirmText: "Esta acción es irreversible.",
    confirmButtonText: "Eliminar Documento",
    successMessage: "¡Documento eliminado!",
    successDescription: "El documento se eliminó exitosamente.",
    errorMessage: "Error al eliminar documento",
    errorDescription: "Hubo un problema al intentar eliminar el documento.",
  };
}
