import { useState } from "react";
import {
    getSolicitudPermisosApi,
    getBossUserApi,
    getPermisosBossApi,
    getPermisosGHApi,
    addSolicitudPermisosApi,
    updateStatusAprovJefeApi,
    updateStatusAprovGHApi,
} from "../../../Api/administrativa/solicitud-permisos/solicitudPermisosApi";
import { useAuth } from "../../useAuth";


export function useSolicitudPer() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    SolicitudPer: null,
    Respuesta: null,
    Boss: null,
  });

  const { loading, error, SolicitudPer, Respuesta, Boss } = state;
  const { auth } = useAuth();

  const getSolicitudPer = async ( id_user ) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getSolicitudPermisosApi(auth.token, id_user);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        SolicitudPer: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getSolicitudesBoss = async ( userId ) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getPermisosBossApi(auth.token, userId);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        SolicitudPer: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getSolicitudesGH = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getPermisosGHApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        SolicitudPer: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getBossUser = async ( id_user ) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getBossUserApi(auth.token, id_user);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Boss: result[0],
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addSolicitudPer = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addSolicitudPermisosApi(data, auth.token);
      setState((prevState) => ({ 
        ...prevState, 
        loading: false ,
        Respuesta: result.message,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateStatusAprovJefe = async ( id_permiso, id_user, estado, observacion ) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateStatusAprovJefeApi( id_permiso, id_user, estado, observacion, auth.token );
      setState((prevState) => ({ 
        ...prevState, 
        loading: false,
        Respuesta: result.message
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateStatusAprovGH = async ( id_permiso, id_user, userMe, estado ) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateStatusAprovGHApi( id_permiso, id_user, userMe, estado, auth.token );
      setState((prevState) => ({ 
        ...prevState, 
        loading: false,
        Respuesta: result.message
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  return {
    loading,
    error,
    Boss,
    Respuesta,
    SolicitudPer,
    getSolicitudPer,
    getSolicitudesBoss,
    getSolicitudesGH,
    getBossUser,
    addSolicitudPer,
    updateStatusAprovJefe,
    updateStatusAprovGH,
  };
}