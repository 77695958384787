import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, IconButton, TableHead, TableRow, TablePagination, Paper, TextField, Typography, Card, Container, Button } from '@mui/material';
import { UnorderedListOutlined } from '@ant-design/icons';
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaReply } from "react-icons/fa6";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dropdown } from "antd";
import { Link } from 'react-router-dom';
import { IconStyle, CellStyle } from "../styles";
import CustomPopover from "../CustomPopover";

export function TableComplaints(props) {
  const {
    Data,
    handleResponder,
    handleEditarData,
    handleEliminarData,
    columns,
    popoverColumns,
    visibleColumns = columns.slice(1),
    popoverVisibleColumns = popoverColumns,
  } = props;

  const [filteredData, setFilteredData] = useState(Data || []);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedData, setSelectedData] = useState(null);
  const [popoverAnchorEl] = useState(null);
  const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
  const open = Boolean(popoverAnchorEl);
  const openMas = Boolean(popoverAnchorMas);
  const popoverMasId = open ? "Data-popover" : undefined;

  useEffect(() => {
    setFilteredData(Data && Array.isArray(Data) ? Data : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Maneja los cambio de filas de pagina
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Maneja los cambios en la barra de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePopoverMasOpen = (event, data) => {
    setPopoverAnchorMas(event.currentTarget);
    setSelectedData(data);
  };

  const handlePopoverMasClose = () => {
    setPopoverAnchorMas(null);
    setSelectedData(null);
  };

  // Filtra los datos basados en el término de búsqueda
  const filteredSearchData = filteredData.filter((data) =>
    columns.some((header) =>
      data[header.field]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Divide los datos para mostrarlos en la página actual
  const rowsToShow = filteredSearchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleConfirmDelete = async (data) => {
    try {
      if (data.id_denuncia && handleEliminarData) {
        await handleEliminarData(data);
      } else { }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  };

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>Historial de denuncias</Typography>
        <TextField
          label='Buscar.....'
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ background: '#DEDEDE', padding: 0 }}>
                {visibleColumns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={CellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={CellStyle}>Detalles</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={CellStyle}>Responder</Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography style={CellStyle}>Edición</Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsToShow &&
                rowsToShow.map((data, index) => {
                  const menuItems = [
                    {
                      key: 'edit',
                      label: 'Editar',
                      onClick: () => handleEditarData(data),
                    },
                    {
                      key: 'delete',
                      label: 'Eliminar',
                      onClick: () => handleConfirmDelete(data),
                    },
                  ];

                  return (
                    <TableRow key={`${data.id_denuncia}-${index}`}>
                      {visibleColumns.map((header) => (
                        <TableCell style={CellStyle} key={`${header.field}-${index}`}>
                          {header.field === 'anexos' && data[header.field] ? (
                            <Link to={data[header.field]} target="_blank" rel="noopener noreferrer">
                              <IoDocumentTextOutline style={{ ...IconStyle, color: '#444444' }} />
                            </Link>
                          ) : (
                            header.render ? header.render(data[header.field]) : String(data[header.field] ?? 'N/A')
                          )}
                        </TableCell>
                      ))}
                      <TableCell style={{ ...CellStyle, textAlign: "center" }}>
                        <IconButton onClick={(event) => handlePopoverMasOpen(event, data)}>
                          <UnorderedListOutlined style={IconStyle} />
                        </IconButton>
                      </TableCell>
                      {handleEditarData && (
                        <TableCell style={{ ...CellStyle, textAlign: "center" }}>
                          <Dropdown
                            menu={{ items: menuItems }}
                            trigger={["click"]}
                          >
                            <IconButton>
                              <MoreVertIcon style={IconStyle} />
                            </IconButton>
                          </Dropdown>
                        </TableCell>
                      )}
                      <TableCell style={CellStyle}>
                        <Button
                          style={{ backgroundColor: '#444444' }}
                          variant="contained"
                          size="small"
                          onClick={() => handleResponder(data)}
                        >
                          <FaReply />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredSearchData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
        />
      </Card>

      <CustomPopover
        id={popoverMasId}
        open={openMas}
        anchorEl={popoverAnchorMas}
        onClose={handlePopoverMasClose}
        selectedData={selectedData}
        Columns={popoverVisibleColumns}
      />
    </Container>
  );
}