import React, { useCallback, useEffect, useState } from 'react';
import { UseDocument } from '../../hooks/managementDocument/UseDocuments';
import { UseTrd } from '../../hooks/managementDocument/UseTrds';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from 'antd';
import { FormDocument } from '../../components/documentManagement/FormDocument';
import { TableDocument } from '../../components/comons/Tables/TableDocument';
import { ShowAlert, ShowOkAlert, messagesDeleteDocument } from "../../components/comons/Alertas/ShowAlerts";

// Definición de los encabezados para la tabla de documentos
const documentHeaders = [
  { label: 'Id_documento', field: 'id_documento' },
  { label: 'Categoría', field: 'nombre_trd' },
  { label: 'Nombre', field: 'nom_documento' },
  { label: 'Fecha Creación', field: 'fecha_creacion' },
  { label: 'Número', field: 'num_documento' },
  { label: 'Emisor', field: 'emisor' },
  { label: 'Fecha Documento', field: 'fecha_documento' },
  { label: 'Vigencia', field: 'fecha_vigencia' },
  { label: 'Archivo', field: 'archivo' },
];

// Definición de columnas adicionales para información breve en la tabla
const popoverColumns = [
  { label: 'Id_documento', field: 'id_documento' },
  { label: 'Id_Retención', field: 'id_trd' },
  { label: 'Valor de la póliza', field: 'valor_poliza' },
  { label: 'Fecha de la póliza', field: 'fecha_poliza' },
  { label: 'Estado de la póliza', field: 'estado_poliza' },
  { label: 'Descripción', field: 'descripcion' },
  { label: 'Observación', field: 'observacion' },
]

export function PageDocument() {
  // Estados locales para controlar el re-fetch, modal y su contenido
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  // Hooks personalizados para manejar documentos y categorías TRD
  const { loading: documentsLoading, documents, getDocuments, deleteDocument } = UseDocument();
  const { getTRD, trds } = UseTrd();

  // Función para alternar el estado de re-fetch
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  // Función para abrir/cerrar el modal
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);

  // useEffect para cargar los documentos y categorías al montar el componente o cambiar `refetch`
  useEffect(() => {
    getDocuments();
    getTRD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
  }, [documents]);

  // Función para manejar la adición de un nuevo documento
  const handleAdd = useCallback(() => {
    setContentModal(<FormDocument onClose={openCloseModal} onRefetch={onRefetch} trds={trds} />);
    openCloseModal();
  }, [openCloseModal, onRefetch, trds]);

  // Función para manejar la eliminación de un documento
  const handleUpdate = useCallback(
    (documents) => {
      setContentModal(
        <FormDocument
          onClose={openCloseModal}
          onRefetch={onRefetch}
          documents={documents}
          trds={trds}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch, trds]
  );

  const handleDeleteDocument = useCallback(
    async (document) => {
      const messages = messagesDeleteDocument();

      try {
        const result = await ShowAlert(
          messages.confirmTitle,
          messages.confirmText,
          'warning',
          messages.confirmButtonText,
          'Cancelar'
        );

        if (result === null) {
          return;
        }

        if (result.isConfirmed) {
          await deleteDocument(document.id_documento);
          await ShowOkAlert(messages.successMessage, messages.successDescription, 'success');
          onRefetch();
        }
      } catch (error) {
        ShowOkAlert(messages.errorMessage, messages.errorDescription, 'error');
      }
    },
    [deleteDocument, onRefetch]
  );

  // Mapeo para enlazar IDs de TRD con nombres legibles
  const trdMap = new Map((trds || []).map((t) => [t.id_trd, t]));

  // Combinar los datos de los documentos con los nombres de las categorías TRD
  const combinedData = (documents || []).map((document) => {
    const trd = trdMap.get(document.id_trd) || {};
    return {
      ...document,
      nombre_trd: trd.nombre_trd || 'No encontrada',
    };
  });

  return (
    <>
      {documentsLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableDocument
          Data={combinedData}
          columns={documentHeaders}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleEliminarDocumento={handleDeleteDocument}
        />
      )}
      <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Documentos"
        destroyOnClose={true}
      >
        {contentModal}
      </Modal>
    </>
  );
}