// layouts
import LayoutComponent from '../Layout';
import LayoutNull from '../Layout/nullLayout';
import ComercialPage from '../pages/CommercialPage/ComercialPage';
import ExcelPage from '../pages/ExcelPage';
import HomePage from '../pages/HomePage';
import { LeadsPage } from '../pages/MarketingPages/LeadsPage';
import { SatisfactionSurveysPage } from '../pages/MarketingPages/SatisfactionSurveysPage';
import { SurveysOrdersInvoicesPage } from '../pages/MarketingPages/SurveysOrdersInvoicesPage';
import { CompetencePage } from '../pages/MarketingPages/CompetencePage';
import { Main } from '../pages/SapPages/Index';
import UserPage from '../pages/UserPage';
import { LineaEticaPage } from '../pages/administrativePages/administrativePage';
import { FormulariosSigPage } from '../pages/administrativePages/formularios_sig';
import { UsuariosDAPage } from '../pages/admon/usuarios/usersPage'
import { ComisionesPage } from '../pages/financiera/comisiones';
import { SyziaPage } from '../pages/syzai/syzaiPage';
import { SolicitudPermisosPage } from '../pages/administrativePages/solicitud_permisos/PermisosPage';
import Login from '../pages/login/LoginPage';
import { FormResetPass } from '../pages/login/resetPass';
import { TabsDocument } from '../pages/documentManagement/TabsDocument';
import { TabsComplaints } from '../pages/complaints/TabsComplaints';

const routesClient = [
  {
    path: "/",
    layout: LayoutNull,
    component: Login,
  },
  {
    path: "/home",
    layout: LayoutComponent,
    component: HomePage,
  },
  {
    path: "/usuarios",
    layout: LayoutComponent,
    component: UsuariosDAPage,
  },
  {
    path: "/archivos",
    layout: LayoutComponent,
    component: UserPage,
  },
  {
    path: "/excel",
    layout: LayoutComponent,
    component: ExcelPage,
  },
  {
    path: "/calendario",
    layout: LayoutComponent,
    component: Main,
  },
  {
    path: "/comercial",
    layout: LayoutComponent,
    component: ComercialPage,
  },
  {
    path: "/competencia",
    layout: LayoutComponent,
    component: CompetencePage,
  },
  {
    path: "/leads",
    layout: LayoutComponent,
    component: LeadsPage,
  },
  {
    path: "/encuestas-satisfaccion",
    layout: LayoutComponent,
    component: SatisfactionSurveysPage,
  },
  {
    path: "/nps-y-ces",
    layout: LayoutComponent,
    component: SurveysOrdersInvoicesPage,
  },
  {
    path: "/linea-etica",
    layout: LayoutComponent,
    component: LineaEticaPage,
  },
  {
    path: "/formularios-sig",
    layout: LayoutComponent,
    component: FormulariosSigPage,
  },
  // {
  //   path: "/comisiones",
  //   layout: LayoutComponent,
  //   component: ComisionesPage,
  // },
  {
    path: "/syzia",
    layout: LayoutComponent,
    component: SyziaPage,
  },
  {
    path: "/reset-password",
    layout: LayoutComponent,
    component: FormResetPass,
  },
  {
    path: "/solicitud-permisos",
    layout: LayoutComponent,
    component: SolicitudPermisosPage,
  },
  {
    path: "/gestor-documental",
    layout: LayoutComponent,
    component: TabsDocument,
  },
  {
    path: "/integridad-laboral",
    layout: LayoutComponent,
    component: TabsComplaints,
  }
];

export default routesClient;
