import { Typography } from 'antd';
const { Title } = Typography;

export const Titulo = () => {
    return (
        <>
            <Title level={2} style={{ fontWeight: 'bold', fontFamily: '-moz-initial', fontSize: '30px', margin: "15px", textAlign: "center" }}>
                Canal de integridad laboral <strong style={{ color: '#CC302B' }}>SYZ</strong>
            </Title>
            <p style={{ textAlign: "justify", margin: "0 auto", width: "80%", fontSize: "16px", color: "#595959" }}>
                Antes de utilizar este mecanismo de reporte, por favor tenga en cuenta las siguientes consideraciones:
            </p>
            <ul style={{ textAlign: "justify", margin: "0 auto", width: "80%", fontSize: "16px", color: "#595959" }}>
                <li>Este canal está destinado exclusivamente para reportar conductas que violen los principios de transparencia e integridad en el ámbito laboral.</li>
                <li>No es un medio para la atención de temas personales.</li>
                <li>Debe ser utilizado con responsabilidad; los hechos reportados deben ser reales, verificables y basados en evidencias.</li>
            </ul>
        </>
    );
};

export const Declaracion = () => {
    return (
        <>
            <p style={{ textAlign: "justify", margin: "0 auto", width: "80%", fontSize: "16px", color: "#595959" }}>
                En cumplimiento de la Ley 1581 de 2012 sobre la que se expide el Régimen General de Protección de Datos Personales,
                el Decreto 1377 de 2013 demás normas que modifiquen, extingan y adicionen en el que se regula expresamente la autorización
                del Titular de información para el tratamiento de los datos personales; SYZ Colombia S.A.S. como encargada y/o responsable
                del tratamiento de datos personales de sus trabajadores, clientes, usuarios finales y/o proveedores y contratistas con los
                que tenga o haya tenido relación, implementa, cumple y da a conocer públicamente los lineamientos establecidos bajo la
                Política de Tratamiento de Datos.
            </p>

        </>
    );
}

