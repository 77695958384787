// Componente FormTrd: Formulario dinámico para gestionar TRD (Tablas de Retención Documental)
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormStyle, ButtonStyleSecond } from "../comons/styles";
import { UseTrd } from '../../hooks/managementDocument/UseTrds';
import { useEffect, useState } from "react";
import { ShowAlert, ShowOkAlert } from "../comons/Alertas/ShowAlerts";

const { Option } = Select;

export function FormTrd(props) {
  // Props para manejar el cierre del formulario, refrescar datos y opciones iniciales
  const { onClose, onRefetch, trds, gestiones = [], areas = [], tipologias = [], categorias = [] } = props;

  // Hooks para manejar la lógica de TRD
  const { addTRD, updateTRD } = UseTrd();

  // Estados locales para manejar los datos filtrados y el estado de carga
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [filteredTipologias, setFilteredTipologias] = useState([]);
  const [filteredCategorias, setFilteredCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Configuración de Formik para la gestión del formulario
  const formik = useFormik({
    initialValues: initialValues(trds),
    validationSchema: trds ? updateSchema() : newSchema(),
    onSubmit: async (formValues) => {
      setIsLoading(true);
      try {
        // Preparar los datos a enviar según los valores del formulario
        const dataToSend = {
          id_gestion: formValues.gestion,
          id_area: formValues.area,
          id_tipologia: formValues.tipologia,
          id_categoria: formValues.categoria,
          anios_retencion: formValues.anios_retencion,
          soporte: formValues.soporte,
          seguridad: formValues.seguridad,
          disposicion_final: formValues.disposicion_final,
        };

        // Determinar si es una operación de creación o actualización
        if (trds) {
          await updateTRD(trds.id_trd, dataToSend);
          ShowOkAlert('Éxito', 'TRD actualizada exitosamente', 'success');
        } else {
          await addTRD(dataToSend);
          ShowOkAlert('Éxito', 'TRD agregada exitosamente', 'success');
        }

        // Refrescar datos y cerrar el formulario
        onRefetch();
        onClose();
      } catch (error) {
        ShowAlert('Error', error?.message || 'Error al procesar la solicitud.', 'error');
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Efecto para filtrar áreas según la gestión seleccionada
  useEffect(() => {
    if (formik.values.gestion && Array.isArray(areas)) {
      const selectedGestion = formik.values.gestion;
      const filtered = areas.filter(area => area.gestion?.id_gestion === selectedGestion);
      setFilteredAreas(filtered);

      // Resetear valores si no son válidos
      if (!filtered.some(area => area.id_area === formik.values.area)) {
        formik.setFieldValue('area', null);
      }

      if (!formik.values.area) {
        setFilteredTipologias([]);
        setFilteredCategorias([]);
        formik.setFieldValue('tipologia', null);
        formik.setFieldValue('categoria', null);
      }
    } else {
      setFilteredAreas([]);
      setFilteredTipologias([]);
      setFilteredCategorias([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.gestion, areas]);

  // Efecto para filtrar tipologías según el área seleccionada
  useEffect(() => {
    if (formik.values.area && Array.isArray(tipologias)) {
      const selectedArea = formik.values.area;
      const filtered = tipologias.filter(tipologia => tipologia.area?.id_area === selectedArea);
      setFilteredTipologias(filtered);

      // Resetear valores si no son válidos
      if (!filtered.some(tipologia => tipologia.id_tipologia === formik.values.tipologia)) {
        formik.setFieldValue('tipologia', null);
      }

      if (!formik.values.tipologia) {
        setFilteredCategorias([]);
        formik.setFieldValue('categoria', null);
      }
    } else {
      setFilteredTipologias([]);
      setFilteredCategorias([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.area, tipologias]);

  // Efecto para filtrar categorías según la tipología seleccionada
  useEffect(() => {
    if (formik.values.tipologia && Array.isArray(categorias)) {
      const selectedTipologia = formik.values.tipologia;
      const filtered = categorias.filter(categoria => categoria.tipologia?.id_tipologia === selectedTipologia);
      setFilteredCategorias(filtered);

      // Resetear valores si no son válidos
      if (!filtered.some(categoria => categoria.id_categoria === formik.values.categoria)) {
        formik.setFieldValue('categoria', null);
      }
    } else {
      setFilteredCategorias([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.tipologia, categorias]);


  return (
    <Spin spinning={isLoading} tip="Procesando...">
      <Form layout="vertical" onFinish={formik.handleSubmit} style={FormStyle}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Gestión"
              validateStatus={formik.errors.gestion && formik.touched.gestion ? "error" : ""}
              help={formik.touched.gestion && formik.errors.gestion ? formik.errors.gestion : ""}
            >
              <Select
                name="gestion"
                value={formik.values.gestion || undefined}
                onChange={(value) => formik.setFieldValue('gestion', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una gestión"
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())}
                disabled={!!trds}
              >
                {gestiones?.map((gestion) => (
                  <Option key={gestion.id_gestion} value={gestion.id_gestion}>
                    {gestion.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Área"
              validateStatus={formik.errors.area && formik.touched.area ? "error" : ""}
              help={formik.touched.area && formik.errors.area ? formik.errors.area : ""}
            >
              <Select
                name="area"
                value={formik.values.area || undefined}
                onChange={(value) => formik.setFieldValue('area', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione un área"
                disabled={!!trds || !formik.values.gestion}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())}
              >
                {filteredAreas?.map((area) => (
                  <Option key={area.id_area} value={area.id_area}>
                    {area.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Tipología"
              validateStatus={formik.errors.tipologia && formik.touched.tipologia ? "error" : ""}
              help={formik.touched.tipologia && formik.errors.tipologia ? formik.errors.tipologia : ""}
            >
              <Select
                name="tipologia"
                value={formik.values.tipologia || undefined}
                onChange={(value) => formik.setFieldValue('tipologia', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una tipología"
                disabled={!!trds || !formik.values.area}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {filteredTipologias?.map((tipologia) => (
                  <Option key={tipologia.id_tipologia} value={tipologia.id_tipologia}>
                    {tipologia.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Categoría"
              validateStatus={formik.errors.categoria && formik.touched.categoria ? "error" : ""}
              help={formik.touched.categoria && formik.errors.categoria ? formik.errors.categoria : ""}
            >
              <Select
                name="categoria"
                value={formik.values.categoria || undefined}
                onChange={(value) => formik.setFieldValue('categoria', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una categoría"
                disabled={!!trds || !formik.values.tipologia}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {filteredCategorias?.map((categoria) => (
                  <Option key={categoria.id_categoria} value={categoria.id_categoria}>
                    {categoria.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Soporte"
              validateStatus={formik.errors.soporte && formik.touched.soporte ? "error" : ""}
              help={formik.touched.soporte && formik.errors.soporte ? formik.errors.soporte : ""}
            >
              <Select
                value={formik.values.soporte || undefined}
                onChange={(value) => formik.setFieldValue('soporte', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una Opción"
              >
                <Option value="Físico">Físico</Option>
                <Option value="Virtual">Virtual</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Seguridad"
              validateStatus={formik.errors.seguridad && formik.touched.seguridad ? "error" : ""}
              help={formik.touched.seguridad && formik.errors.seguridad ? formik.errors.seguridad : ""}
            >
              <Select
                value={formik.values.seguridad || undefined}
                onChange={(value) => formik.setFieldValue('seguridad', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una Opción"
              >
                <Option value="Público">Público</Option>
                <Option value="Interno">Interno</Option>
                <Option value="Confidencial">Confidencial</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Disposición Final"
              validateStatus={formik.errors.disposicion_final && formik.touched.disposicion_final ? "error" : ""}
              help={formik.touched.disposicion_final && formik.errors.disposicion_final ? formik.errors.disposicion_final : ""}
            >
              <Select
                value={formik.values.disposicion_final || undefined}
                onChange={(value) => formik.setFieldValue('disposicion_final', value)}
                onBlur={formik.handleBlur}
                placeholder="Seleccione una Opción"
              >
                <Option value="Conservación Total">Conservación Total</Option>
                <Option value="Selección">Selección</Option>
                <Option value="Eliminación">Eliminación</Option>
                <Option value="Digitalización">Digitalización</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Años de Retención"
              validateStatus={formik.errors.anios_retencion && formik.touched.anios_retencion ? "error" : ""}
              help={formik.touched.anios_retencion && formik.errors.anios_retencion ? formik.errors.anios_retencion : ""}
            >
              <Input
                type="text"
                name="anios_retencion"
                value={formik.values.anios_retencion}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '') {
                    formik.setFieldValue("anios_retencion", '');
                    return;
                  }
                  const intValue = parseInt(value, 10);
                  if (!isNaN(intValue) && intValue >= 1 && intValue <= 25) {
                    formik.setFieldValue("anios_retencion", intValue);
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder="Ingrese un número entre 1 y 25"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={ButtonStyleSecond}>
                {trds ? "Actualizar" : "Crear"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

// Función para definir valores iniciales del formulario
function initialValues(data) {
  return {
    gestion: data?.id_gestion || null,
    area: data?.id_area || null,
    tipologia: data?.id_tipologia || null,
    categoria: data?.id_categoria || null,
    anios_retencion: data?.anios_retencion || "",
    soporte: data?.soporte || "",
    seguridad: data?.seguridad || "",
    disposicion_final: data?.disposicion_final || "",
  };
}

// Esquema de validación para la creación de TRD
function newSchema() {
  return Yup.object({
    gestion: Yup.string().required("Requerido"),
    area: Yup.string().required("Requerido"),
    tipologia: Yup.string().required("Requerido"),
    categoria: Yup.string().required("Requerido"),
    anios_retencion: Yup.number().min(1, "Mínimo 1 año").typeError("Debe ser un número").required("Requerido"),
    soporte: Yup.string().required("Requerido"),
    seguridad: Yup.string().required("Requerido"),
    disposicion_final: Yup.string().required("Requerido"),
  });
}

// Esquema de validación para la actualización de TRD
function updateSchema() {
  return Yup.object({
    gestion: Yup.string().required("Requerido"),
    area: Yup.string().required("Requerido"),
    tipologia: Yup.string().required("Requerido"),
    categoria: Yup.string().required("Requerido"),
    anios_retencion: Yup.number().min(1, "Mínimo 1 año").typeError("Debe ser un número").required("Requerido"),
    soporte: Yup.string().required("Requerido"),
    seguridad: Yup.string().required("Requerido"),
    disposicion_final: Yup.string().required("Requerido"),
  });
}