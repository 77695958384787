import { useState } from "react";
import { getGestionesApi, getAreasApi, getTipologiasApi, getCategoriasApi } from "../../Api/documentManagement/RequestsOther";
import { useAuth } from "../useAuth";

export function UseOther() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    gestiones: null,
    areas: null,
    tipologias: null,
    categorias: null,
  });

  const { loading, error, gestiones, areas, tipologias, categorias } = state;
  const { auth } = useAuth();

  // Función de carga que maneja almacenamiento en caché en localStorage
  const loadData = async (key, apiCall) => {
    const cacheTimestampKey = `${key}_timestamp`;
    const cachedData = localStorage.getItem(key);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

    if (cachedData && cachedTimestamp && Date.now()) {
      try {
        return JSON.parse(cachedData);
      } catch (error) {
        console.warn(`Error parsing ${key} from localStorage:`, error);
        localStorage.removeItem(key);
      }
    }

    const [result] = await apiCall(auth.token);
    if (result) {
      localStorage.setItem(key, JSON.stringify(result));
      localStorage.setItem(cacheTimestampKey, Date.now());
    }
    return result || [];
  };

  const getGestiones = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const gestiones = await loadData("gestiones", getGestionesApi);
      setState((prevState) => ({ ...prevState, loading: false, gestiones }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getAreas = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const areas = await loadData("areas", getAreasApi);
      setState((prevState) => ({ ...prevState, loading: false, areas }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getTipologias = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const tipologias = await loadData("tipologias", getTipologiasApi);
      setState((prevState) => ({ ...prevState, loading: false, tipologias }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getCategorias = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const categorias = await loadData("categorias", getCategoriasApi);
      setState((prevState) => ({ ...prevState, loading: false, categorias }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    gestiones,
    areas,
    tipologias,
    categorias,
    getGestiones,
    getAreas,
    getTipologias,
    getCategorias,
  };
}