import { Tabs } from 'antd';
import { FormComplaints } from '../../components/complaints/FormComplaints'
import { PageComplaints } from './PageComplaints'

export function TabsComplaints() {
	const items = [
		{
			key: '1',
			label: 'Integridad Laboral',
			children: <FormComplaints />
		},
		{
			key: '2',
			label: 'Reportes',
			children: <PageComplaints />
		}
	]

	return (
		<>
			<Tabs
				defaultActiveKey='1'
				items={items}
				destroyInactiveTabPane
			/>
		</>
	)
}
