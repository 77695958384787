import { Tabs } from 'antd';
import { PageTrd } from './PageTrds'
import { PageDocument } from './PageDocument'

export function TabsDocument() {
  const items = [
    {
      key: '1',
      label: 'Documentos',
      children: <PageDocument />
    },
    {
      key: '2',
      label: 'Retención Documental',
      children: <PageTrd />
    }
  ]

  return (
    <>
      <Tabs
        defaultActiveKey='1'
        items={items}
        destroyInactiveTabPane
      />
    </>
  )
}
