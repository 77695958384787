import { BASE_API } from "../../utils/constants";
import { makeRequest, makeRequestFileData } from "../Request";

function checkToken(token) {
  if (!token) throw new Error("Se requiere token para esta solicitud");
}

// Funicon que permite obtener una denuncia
export async function getDenunciaApi(token) {
  checkToken(token);
  const url = `${BASE_API}/integridad/api/denuncias/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite agregar una denuncia
export async function addDenunciaApi(data, token) {
  checkToken(token);
  const url = `${BASE_API}/integridad/api/denuncias/create_with_related/`;
  return makeRequestFileData(url, "POST", token, data);
}

// Funicon que permite actualizar una denuncia
export async function updateDenunciaApi(id, data, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un id para actualizar");
  const url = `${BASE_API}/integridad/api/denuncias/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Funicon que permite eliminar una denuncia
export async function deleteDenunciaApi(id, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un id para eliminar");
  const url = `${BASE_API}/integridad/api/denuncias/${id}/`;
  return makeRequest(url, "DELETE", token);
}

// Función que permite actualizar la respuesta de una denuncia
export async function updateRespuestaDenunciaApi(id, data, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un id para actualizar la respuesta");
  const url = `${BASE_API}/integridad/api/denuncias/${id}/responder/`;
  return makeRequestFileData(url, "POST", token, data);
}