import { useState } from "react";
import { getTrdApi, addTrdApi, updateTrdApi, deleteTrdApi } from "../../Api/documentManagement/RequestsTrds";
import { useAuth } from "../useAuth";

// Custom hook para manejar la gestión de TRD (Tablas de Retención Documental)
export function UseTrd() {
  const [state, setState] = useState({ loading: true, error: null, trds: null, });

  const { loading, error, trds } = state;
  const { auth } = useAuth();

  // Función para obtener las TRD desde la API
  const getTRD = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result] = await getTrdApi(auth.token);
      setState((prevState) => ({ ...prevState, loading: false, trds: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  // Función para agregar una nueva TRD a través de la API
  const addTRD = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await addTrdApi(data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  // Función para actualizar una TRD existente a través de la API
  const updateTRD = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateTrdApi(id, data, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  // Función para eliminar una TRD específica a través de la API
  const deleteTRD = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await deleteTrdApi(id, auth.token);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error
    }
  }

  // Devuelve los datos y las funciones que pueden ser utilizadas por otros componentes
  return {
    loading,
    error,
    trds,
    getTRD,
    addTRD,
    updateTRD,
    deleteTRD,
  };
}