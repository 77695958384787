import { BASE_API } from '../../utils/constants';
import { makeRequest } from '../Request';

function checkToken(token) {
  if (!token) throw new Error("Se requiere token para esta solicitud");
}

// Funicon que permite obtener los TRD
export async function getTrdApi(token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/trds/`;
  return makeRequest(url, "GET", token);
}

// Funicon que permite agregar TRD
export async function addTrdApi(data, token) {
  checkToken(token);
  const url = `${BASE_API}/GestionDocumental/api/trds/`;
  return makeRequest(url, "POST", token, data);
}

// Funicon que permite actualizar TRD
export async function updateTrdApi(id, data, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un ID para actualizar el TRD");
  const url = `${BASE_API}/GestionDocumental/api/trds/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Funicon que permite eliminar TRD
export async function deleteTrdApi(id, token) {
  checkToken(token);
  if (!id) throw new Error("Se requiere un ID para eliminar el TRD");
  const url = `${BASE_API}/GestionDocumental/api/trds/${id}/`;
  return makeRequest(url, "DELETE", token);
}