// TableDocument.js
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography, TableHead } from "@mui/material";
import { Dropdown } from "antd";
import { UnorderedListOutlined, EditOutlined  } from '@ant-design/icons';
import { IoDocumentTextOutline } from "react-icons/io5";
import CustomPopover from "../CustomPopover";
import { CellStyle, ButtonStyle, IconStyle } from "../styles";

export function TableDocument(props) {
  const {
    Data,
    handleAgregarData,
    handleEditarData,
    handleEliminarDocumento,
    handleEliminarTrd,
    columns,
    popoverColumns,
    visibleColumns = columns.slice(1),
    popoverVisibleColumns = popoverColumns,
  } = props;

  const uppercaseColumns = columns.map(header => ({
    ...header,
    label: header.label.toUpperCase(),
  }));

  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverAnchorEl] = useState(null);
  const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const open = Boolean(popoverAnchorEl);
  const openMas = Boolean(popoverAnchorMas);
  const popoverMasId = open ? "Data-popover" : undefined;

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (Array.isArray(Data)) {
      setFilteredData(Data);
    }
  }, [Data]);

  const filteredSearchData = filteredData.filter((data) =>
    uppercaseColumns.some((header) =>
      data[header.field]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const rowsToShow = filteredSearchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePopoverMasOpen = (event, data) => {
    setPopoverAnchorMas(event.currentTarget);
    setSelectedData(data);
  };

  const handlePopoverMasClose = () => {
    setPopoverAnchorMas(null);
    setSelectedData(null);
  };

  const handleConfirmDelete = async (data) => {
    try {
      if (data.id_documento && handleEliminarDocumento) {
        await handleEliminarDocumento(data);
      } else if (data.id_trd && handleEliminarTrd) {
        await handleEliminarTrd(data);
      } else { }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  };

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label='Buscar.....'
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <div>
          {handleAgregarData && (
            <Button
              variant="contained"
              style={ButtonStyle}
              onClick={() => handleAgregarData()}
            >Agregar Nuevo</Button>
          )}
        </div>
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ background: '#DEDEDE', padding: 0 }}>
                {visibleColumns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={CellStyle}>
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={CellStyle}>Detalles</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={CellStyle}>Editar</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsToShow &&
                rowsToShow.map((data, index) => {
                  const menuItems = [
                    {
                      key: 'edit',
                      label: 'Editar',
                      onClick: () => handleEditarData(data),
                    },
                    {
                      key: 'delete',
                      label: 'Eliminar',
                      onClick: () => handleConfirmDelete(data),
                    },
                  ];

                  return (
                    <TableRow key={`${data.id_trd}-${index}`}>
                      {visibleColumns.map((header) => (
                        <TableCell style={CellStyle} key={`${header.field}-${index}`}>
                          {header.field === 'archivo' && data[header.field] ? (
                            <Link to={data[header.field]} target="_blank" rel="noopener noreferrer">
                              <IoDocumentTextOutline style={{ ...IconStyle, color: '#444444' }} />
                            </Link>
                          ) : (
                            data[header.field] ?? 'N/A'
                          )}
                        </TableCell>
                      ))}
                      <TableCell style={{ ...CellStyle, textAlign: "center" }}>
                        <IconButton onClick={(event) => handlePopoverMasOpen(event, data)}>
                          <UnorderedListOutlined style={IconStyle} />
                        </IconButton>
                      </TableCell>
                      {handleEditarData && (
                        <TableCell style={{ ...CellStyle, textAlign: "center" }}>
                          <Dropdown
                            menu={{ items: menuItems }}
                            trigger={["click"]}
                          >
                            <IconButton>
                              <EditOutlined style={IconStyle} />
                            </IconButton>
                          </Dropdown>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredSearchData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
        />
      </Card>

      <CustomPopover
        id={popoverMasId}
        open={openMas}
        anchorEl={popoverAnchorMas}
        onClose={handlePopoverMasClose}
        selectedData={selectedData}
        Columns={popoverVisibleColumns}
      />
    </Container>
  );
}